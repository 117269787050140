* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Nunito !important;
}

html,
body {
  width: 100%;
  overflow-x: hidden !important;
  margin: 0 auto;
  color: #252525 !important;
  background: #f2f2f2 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

@font-face {
  font-family: "Avenir";
  src: url("/fonts/Avenir-Regular.otf");
}

@font-face {
  font-family: "Noto Sans";
  src: url("/fonts/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "Avenir Next";
  src: url("/fonts/Avenir-Next.ttc");
  font-stretch: condensed;
}

@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito-Regular.ttf");
}
